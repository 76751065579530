/** 专利详情 */
<template>
  <div class="patent-details">
    <section v-if="patentDetailsData" class="details-info back-fff mb-10">
      <div class="content">
        <div class="justify-between-center">
          <div class="details-name">
            <h4 class="mb-10">{{ catalogPatent.tio }}</h4>
            <div class="enterprise-tag">
              <el-tag class="mr-8" v-for="(item, index) in detailsTags" :key="index" v-show="item" color="#6AA2FF"
                effect="dark" size="small ">
                {{ item }}
              </el-tag>
            </div>
          </div>
          <div class="details-btns">
            <el-button class="monitor-btn monitor-annualCost-btn" :loading="monitor_annualCost_load"
              @click="monitorAnnualCostBtn(catalogPatent.ano)">
              {{
                !$store.getters.userToken ||
                !catalogPatent.associate_annualfee_status
                  ? "年费监控"
                  : "取消年费监控"
              }}</el-button>
            <el-button class="monitor-btn" :loading="monitor_load" @click="monitorBtn(catalogPatent.pid)">
              {{
                !$store.getters.userToken ||
                !catalogPatent.associate_patent_status
                  ? "专利监控"
                  : "取消专利监控"
              }}</el-button>
            <el-button class="report-download theme-back ml-20" @click="$store.dispatch('setDownloadDialog', true)">报告下载
            </el-button>
          </div>
        </div>
        <ul class="details-text pt-32 flex-wrap">
          <li v-if="catalogPatent.apo">申请人：{{ catalogPatent.apo }}</li>
          <li v-if="catalogPatent.ino">发明人：{{ catalogPatent.ino }}</li>
          <li v-if="catalogPatent.aspo">专利权人：{{ catalogPatent.aspo }}</li>
          <li v-if="catalogPatent.agc">
            代理机构：<router-link :to="{
                path: '/enterprise-details',
                query: { qn: catalogPatent.agc },
              }">
              {{ catalogPatent.agc }}
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section v-if="patentDetailsData" class="back-fff pb-140">
      <div class="content">
        <el-tabs v-model="detailsName" type="card" @tab-click="handleTabs">
          <el-tab-pane label="基础数据" name="tabs1">
            <AssetsDescriptions :data="infoData.data" :labelData="infoData.label">
              <template v-slot:absoHTML="{ item }">
                <html-panel v-if="infoData.data[item.value]" id="iframe1" :url.sync="infoData.data[item.value]">
                </html-panel>
                <div v-else v-html="infoData.data.debec"></div>
              </template>
            </AssetsDescriptions>
          </el-tab-pane>

          <el-tab-pane label="权利要求" name="tabs2">
            <ul class="details-main">
              <li class="details-main-item">
                <h4 class="details-main-title">权利要求书</h4>
                <div class="details-main-con flex-wrap">
                  <span>公开号：{{ catalogPatent.pno }}</span>
                  <span>权利要求数量：{{ catalogPatent.cln }}项</span>
                  <span>独立权利要求数量：{{ catalogPatent.icln || 0 }}项</span>
                </div>
              </li>
              <li class="details-main-item">
                <h4 class="details-main-title">权利要求</h4>
                <html-panel id="iframe2" :url.sync="patentDetailsData.claoHTML"></html-panel>
              </li>
            </ul>
          </el-tab-pane>

          <el-tab-pane label="专利说明书" name="tabs3">
            <ul class="details-main">
              <li class="details-main-item">
                <html-panel id="iframe3" :url.sync="patentDetailsData.desoHTML"></html-panel>
              </li>
            </ul>
          </el-tab-pane>

          <el-tab-pane label="专利附图" name="tabs4">
            <el-row class="details-main" :gutter="13">
              <el-col :span="6" class="details-main-item patent-figure" v-for="(item, index) in imgArray" :key="index">
                <el-image class="patent-figure-img" :src="item" :preview-src-list="imgArray" fit="contain">
                </el-image>
              </el-col>
            </el-row>
            <el-button v-if="imgArray.length > 16" class="view-more mt-32" @click="viewMore">查看更多</el-button>
          </el-tab-pane>

          <el-tab-pane label="法律数据" name="tabs5">
            <ul class="details-main">
              <li class="details-main-item">
                <AssetsDescriptions title="当前法律状态" :data="legalDataOne.data" :labelData="legalDataOne.label">
                  <template v-slot:my_ilsso="{ item }">
                    {{ legalDataOne.data[item.value] ? "是" : "否" }}
                  </template>
                </AssetsDescriptions>
              </li>
              <li class="details-main-item">
                <AssetsTable title="法律状态信息历史" :data="legalDataTwo.data" :labelData="legalDataTwo.label" />
              </li>
              <li class="details-main-item">
                <AssetsDescriptions title="年费信息" :data="legalDataThree.data" :labelData="legalDataThree.label" />
              </li>
              <li class="details-main-item">
                <AssetsTable title="专利审查信息" :data="legalDataFour.data" :labelData="legalDataFour.label">
                  <template v-slot:cid="{ scope }">
                    <router-link :to="{
                        name: 'PatentLawInfo',
                        query: { id: scope.row.cid },
                        params: { lawType: 'review' },
                      }">
                      <el-button type="text">详情</el-button>
                    </router-link>
                  </template>
                </AssetsTable>
              </li>
            </ul>
          </el-tab-pane>

          <el-tab-pane label="运营数据" name="tabs6">
            <ul class="details-main">
              <li class="details-main-item">
                <AssetsTable title="专利权转移信息" :data="operatingDataOne.data" :labelData="operatingDataOne.label" />
              </li>
              <li class="details-main-item">
                <AssetsTable title="专利权许可信息" :data="operatingDataTwo.data" :labelData="operatingDataTwo.label">
                  <template v-slot:active="{ scope }">
                    <el-button type="text" @click="handleOperatingVisible('license', scope.row)">详情</el-button>
                  </template>
                </AssetsTable>
              </li>
              <li class="details-main-item">
                <AssetsTable title="专利权质押信息" :data="operatingDataThree.data" :labelData="operatingDataThree.label">
                  <template v-slot:active="{ scope }">
                    <el-button type="text" @click="handleOperatingVisible('pledge', scope.row)">详情</el-button>
                  </template>
                </AssetsTable>
              </li>
            </ul>
          </el-tab-pane>

          <el-tab-pane label="引用数据" name="tabs7">
            <ul class="details-main">
              <li class="details-main-item" v-if="
                  referenceDataOne.data.length || referenceDataTwo.data.length
                ">
                <h4 class="details-main-title">专利引用关系图</h4>
                <div class="details-main-con patent-reference">
                  <MyRelationGraph v-if="referenceLoading" :rootId="{ id: catalogPatent.pid, text: catalogPatent.ano }"
                    :dataLeft="referenceDataOne.data" :dataRight="referenceDataTwo.data" />
                </div>
              </li>
              <li class="details-main-item">
                <AssetsTable title="专利引用关系表" :data="referenceDataOne.data" :labelData="referenceDataOne.label">
                  <template v-slot:pid="{ scope }">
                    <el-button class="monitor-btn" :loading="scope.row.monitor_load" @click="
                        listMonitorBtn(
                          scope.row.pid,
                          scope.$index,
                          'referenceDataOne'
                        )
                      ">
                      {{
                        !$store.getters.userToken ||
                        !scope.row.associate_patent_status
                          ? "监控"
                          : "取消监控"
                      }}</el-button>
                  </template>
                </AssetsTable>
              </li>
              <li class="details-main-item">
                <AssetsTable title="专利被引用关系表" :data="referenceDataTwo.data" :labelData="referenceDataTwo.label">
                  <template v-slot:pid="{ scope }">
                    <el-button class="monitor-btn" :loading="scope.row.monitor_load" @click="
                        listMonitorBtn(
                          scope.row.pid,
                          scope.$index,
                          'referenceDataTwo'
                        )
                      ">
                      {{
                        !$store.getters.userToken ||
                        !scope.row.associate_patent_status
                          ? "监控"
                          : "取消监控"
                      }}</el-button>
                  </template>
                </AssetsTable>
              </li>
            </ul>
          </el-tab-pane>

          <el-tab-pane label="专利族" name="tabs8">
            <ul class="details-main">
              <li class="details-main-item">
                <AssetsTable :title="`专利族（族内专利数量：${
                    patentFamilyData.data ? patentFamilyData.data.length : 0
                  }件）`" :data="patentFamilyData.data" :labelData="patentFamilyData.label" />
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
    <!-- 下载框 -->
    <AssetsDownload v-if="patentDetailsData" downloadType="patent" :downloadVal="catalogPatent.pid" />
    <!-- 专利运营数据许可/质押--详情展示框 -->
    <el-dialog :title="`专利${operatingVisibleTitle}详情`" :visible.sync="operatingVisible" :lock-scroll="false"
      width="70%">
      <AssetsDescriptions v-if="operatingVisibleTitle == '许可'" :data="operatingLicenseVisibleData.data"
        :labelData="operatingLicenseVisibleData.label" />
      <AssetsDescriptions v-else :data="operatingPledgeVisibleData.data"
        :labelData="operatingPledgeVisibleData.label" />
    </el-dialog>
  </div>
</template>
<script>
import AssetsDescriptions from "@/components/Assets/descriptions.vue";
import AssetsTable from "@/components/Assets/table.vue";
import AssetsDownload from "@/components/Assets/download.vue";
import HtmlPanel from "@/components/HtmlPanel.vue";
import MyRelationGraph from "@/components/MyRelationGraph.vue";
import {
  getPatentDetails,
  setPatentMonitor,
  setPatentMonitorAnnualCost,
  delPatentMonitorAnnualCost,
  getPatentLawHistory,
  getPatentDecision,
  getPatentTransfer,
  getPatentLicense,
  getPatentPledge,
  getPatentReference,
  getPatentFamily,
} from "@/api/patent";
export default {
  name: "PatentDetails",
  components: {
    AssetsDescriptions,
    AssetsTable,
    AssetsDownload,
    HtmlPanel,
    MyRelationGraph,
  },
  data () {
    return {
      operatingVisible: false, // 专利运营数据许可/质押--详情展示框
      operatingVisibleTitle: "", // 专利运营数据许可/质押--详情展示框
      monitor_load: false, // 监控按钮加载状态
      monitor_annualCost_load: false,
      figurePage: 0, // 专利附图-页码
      detailsName: "tabs1",
      patentDetailsData: null,
      detailsTags: [], //详情标签
      catalogPatent: null, //基本数据
      imgArray: [], // 专利附图--计算需展示的数量
      imgArrayAll: [], // 专利附图
      infoData: {
        label: [
          { label: "专利名称", value: "tio" },
          { label: "申请日 ", value: "ad" },
          { label: "专利号", value: "ano" },
          { label: "申请公开日", value: "pd" },
          { label: "公开(公告)号", value: "pno" },
          { label: "授权公开日", value: "my_ilsad" },
          { label: "发明人", value: "ino" },
          { label: "IPC分类号", value: "ipc" },
          { label: "初始申请人", value: "apo" },
          { label: "当前专利权人", value: "aspo" },
          { label: "代理机构", value: "agc" },
          { label: "代理人", value: "ag" },
          { label: "申请人地址", value: "ap1adc", span: "2" },
          { label: "摘要", value: "absoHTML", span: "2", isSlot: true },
          // { label: '摘要附图', value: 'imgArr', span: '2', isSlot: true },
        ],
        data: null,
      },
      // 法律数据--当前法律状态
      legalDataOne: {
        label: [
          { label: "当前状态", value: "lssc" },
          { label: "审查时长", value: "review" },
          { label: "申请日 ", value: "ad" },
          { label: "首次公开日", value: "pd" },
          { label: "是否曾经授权", value: "my_ilsso", isSlot: true },
          { label: "授权日", value: "my_ilsad" },
          { label: "存活期", value: "survive" },
          { label: "失效日", value: "rd" },
        ],
        data: null,
      },
      // 法律数据--法律状态信息历史
      legalDataTwo: {
        label: [
          { label: "法律信息公告日", value: "ilsad" },
          { label: "法律状态类型", value: "ilsc" },
          { label: "状态详情", value: "ilsso" },
          { label: "描述", value: "ilsic" },
        ],
        data: null,
      },
      // 法律数据--年度信息
      legalDataThree: {
        label: [
          { label: "专利预计到期日", value: "end_date", span: "2" },
          { label: "专利年费信息", value: "advice_date", span: "2", text: '' },
        ],
        data: null,
      },
      // 法律数据--专利审查信息
      legalDataFour: {
        label: [
          { label: "类型", value: "ridt" },
          { label: "决定号", value: "ridn" },
          { label: "请求人", value: "my_apc" },
          { label: "请求日", value: "ad" },
          { label: "决定日", value: "ridd" },
          { label: "操作", value: "cid", isSlot: true },
        ],
        data: null,
      },
      // 运营数据--专利权转移信息
      operatingDataOne: {
        label: [
          { label: "转移类型", value: "tt_cn" },
          { label: "专利权转移生效日", value: "my_edd" },
          { label: "专利权转移原权利人", value: "pasc" },
          { label: "专利权转移新权利人", value: "aasc" },
        ],
        data: [],
      },
      // 运营数据--专利权许可信息
      operatingDataTwo: {
        label: [
          { label: "专利备案合同号编码", value: "my_crn" },
          { label: "专利权许可生效日", value: "my_crdd" },
          { label: "许可状态", value: "rldd_cn" },
          { label: "许可人/被许可人", value: "myLicensePeople" },
          { label: "操作", value: "active", isSlot: true },
        ],
        data: null,
      },
      // 运营数据--专利权质押信息
      operatingDataThree: {
        label: [
          { label: "质押登记号", value: "pprn" },
          { label: "质押状态", value: "ppt" },
          { label: "事件状态", value: "pprdd_cn" },
          { label: "出质人/质权人", value: "myPledgePeople" },
          { label: "操作", value: "active", isSlot: true },
        ],
        data: null,
      },
      // 运营数据--许可--详情
      operatingLicenseVisibleData: {
        label: [
          { label: "专利名称", value: "tio", span: "2" },
          { label: "专利类型", value: "pdt" },
          { label: "申请日", value: "ad" },
          { label: "专利许可类型", value: "pt" },
          { label: "专利许可备案号", value: "my_crn" },
          { label: "合同备案日期", value: "my_crdd" },
          { label: "合同变更日期", value: "pcdd" },
          { label: "合同解除日期", value: "rldd" },
          { label: "", value: "" },
          { label: "许可人", value: "trc", span: "2" },
          { label: "被许可人", value: "trpc" },
        ],
        data: null,
      },
      // 运营数据--质押--详情
      operatingPledgeVisibleData: {
        label: [
          { label: "专利名称", value: "tio", span: "2" },
          { label: "专利类型", value: "pdt" },
          { label: "申请日", value: "ad" },
          { label: "质押保全类型", value: "ppt" },
          { label: "质押保全权力类型", value: "pprs" },
          { label: "质押登记号", value: "pprn" },
          { label: "生效日期", value: "ppedd" },
          { label: "变更日期", value: "ppdd" },
          { label: "解除日期", value: "pprdd" },
          { label: "出质人", value: "ppoc", span: "2" },
          { label: "质权人", value: "pppc" },
        ],
        data: null,
      },
      // 引用数据关系图显示/隐藏
      referenceLoading: false,
      // 引用数据--专利引用关系表
      referenceDataOne: {
        label: [
          { label: "法律状态", value: "lsscn" },
          { label: "申请号", value: "ano" },
          { label: "申请日", value: "ad" },
          { label: "专利名称", value: "tio" },
          { label: "申请人", value: "apo" },
          { label: "操作", value: "pid", isSlot: true },
        ],
        data: [],
        pageTotal: 0,
      },
      // 引用数据--专利被引用关系表
      referenceDataTwo: {
        label: [
          { label: "法律状态", value: "lsscn" },
          { label: "申请号", value: "ano" },
          { label: "申请日", value: "ad" },
          { label: "专利名称", value: "tio" },
          { label: "申请人", value: "apo" },
          { label: "操作", value: "pid", isSlot: true },
        ],
        data: [],
        pageTotal: 0,
      },
      // 专利族
      patentFamilyData: {
        label: [
          { label: "专利号", value: "pns" },
          { label: "申请日", value: "ad" },
          { label: "法律状态", value: "lsscn" },
          { label: "专利权人", value: "aspo" },
          { label: "公开日", value: "pd" },
          { label: "优先权", value: "" },
          { label: "专利名称", value: "tic" },
        ],
        data: null,
      },
    };
  },
  watch: {
    $route (newVal) {
      this.getPatentDetails();
    },
  },
  mounted () {
    this.$store.dispatch('setQueryType', 'patent')
    this.getPatentDetails();
  },
  methods: {
    // 获取专利详情数据
    async getPatentDetails () {
      try {
        let { data, status } = await getPatentDetails(this.$route.query.pid);
        if (status == 200) {
          this.patentDetailsData = data;
          this.catalogPatent = data.catalogPatent;
          // 详情标签
          this.detailsTags = [
            this.catalogPatent.ano,
            this.catalogPatent.ac,
            data.pdtStr,
            this.catalogPatent.lssc,
          ];
          // 专利附图
          if (data.imgArray) {
            this.imgArrayAll = data.imgArray.map((i) => {
              for (const key in i) {
                return i[key];
              }
            });
          }
          // 基本数据
          this.infoData.data = {
            ...data.catalogPatent,
            debec: data.debec,
            absoHTML: data.absoHTML,
            my_ilsad: "",
          };
          // 法律数据--当前法律状态
          this.legalDataOne.data = {
            ...data.catalogPatent,
            my_ilsso: "",
            my_ilsad: "",
          };
          // 法律数据--年度信息
          this.legalDataThree.data = { ...data.catalogPatent };
          //获取专利法律状态信息历史--授权公开日
          await this.getPatentLawHistory();
          // 展示专利附图
          this.viewMore();
          // 请求当前tab内所需数据
          this.handleTabs({ name: this.detailsName });
        }
      } catch (error) {
        return false;
      }
    },
    // 计算第几年年费
    countPatentContent () {
      let patent_content = 1
      // 申请日
      let adDate = new Date(this.legalDataOne.data.ad);
      let adYear = adDate.getFullYear();
      let adMonth = adDate.getMonth() + 1;
      let adDay = adDate.getDate();
      // 当前日期
      let nowDate = new Date()
      let nowYear = nowDate.getFullYear();
      // 当年应缴日期时间戳
      let countNowDate = new Date(nowYear + "-" + adMonth + "-" + adDay).getTime()
      // 当前时间戳
      let nowDateStamp = nowDate.getTime()
      patent_content += (nowYear - adYear)
      if (nowDateStamp > countNowDate) {
        patent_content += 1
      }
      if (this.legalDataThree.data.ac.toUpperCase() == 'CN') {
        if (this.infoData.data.lssc == "有效" || this.infoData.data.lssc == "有权") {
          let isPaid = false;
          switch (this.patentDetailsData.pdtStr) {
            case "发明":
              if (patent_content > 20) {
                isPaid = true;
              }
              break;
            case "实用新型":
              if (patent_content > 10) {
                isPaid = true;
              }
              break;
            case "外观设计":
              if (patent_content > 15) {
                isPaid = true;
              }
              break;
            default:
              break;
          }
          this.legalDataThree.label[1].text = isPaid ? '有效期内已缴完所有年费' : `（第${patent_content}年年费建议缴纳时间）`
        }
      } else {
        this.legalDataThree.data.advice_date = ''
      }

    },
    // 专利法律状态信息历史
    async getPatentLawHistory () {
      try {
        let { data, status } = await getPatentLawHistory(this.$route.query.pid);
        if (status == 200) {
          this.legalDataTwo.data = data;
          let dataItem = data.find((item) => item.ilsso == "授权") || this.infoData.data.lssc == "有效" || this.infoData.data.lssc == "有权";
          if (dataItem) {
            this.legalDataOne.data["my_ilsso"] = "授权";
            this.legalDataOne.data["my_ilsad"] = dataItem.ilsad;
            this.infoData.data["my_ilsad"] = dataItem.ilsad;
            this.countPatentContent()
          }
        }
      } catch (error) {
        return false;
      }
    },
    // 专利审查列表
    async getPatentDecision (id) {
      try {
        let { data, status } = await getPatentDecision(id);
        if (status == 200) {
          data.data.forEach((item) => {
            item["my_apc"] = item.apc && item.apc.split(";");
          });
          this.legalDataFour.data = data.data;
        }
      } catch (error) {
        return false;
      }
    },
    // 专利权转移信息
    async getPatentTransfer (page = 1) {
      try {
        let { data, status } = await getPatentTransfer(
          this.catalogPatent.ano,
          page
        );
        if (status == 200) {
          data.data.forEach((item) => {
            item["my_edd"] = item.edd && item.edd.split(";");
          });
          this.operatingDataOne.data = data.data;
        }
      } catch (error) {
        return false;
      }
    },
    // 专利权许可信息
    async getPatentLicense (page = 1) {
      try {
        let { data, status } = await getPatentLicense(
          this.catalogPatent.ano,
          page
        );
        if (status == 200) {
          data.data.forEach((item) => {
            item["my_crdd"] = item.crdd && item.crdd.split(";");
            item["myLicensePeople"] = [
              `许可人：${item.trc}`,
              `被许可人：${item.trpc}`,
            ];
            item["my_crn"] = item.crn && item.crn.split(";");
            item["pdt"] = this.patentDetailsData.pdtStr;
          });
          this.operatingDataTwo.data = data.data;
        }
      } catch (error) {
        return false;
      }
    },
    // 专利权质押信息
    async getPatentPledge (page = 1) {
      try {
        let { data, status } = await getPatentPledge(
          this.catalogPatent.ano,
          page
        );
        if (status == 200) {
          data.data[0].forEach((item) => {
            item["myPledgePeople"] = [
              `出质人：${item.ppoc}`,
              `质权人：${item.pppc}`,
            ];
          });
          this.operatingDataThree.data = data.data[0];
        }
      } catch (error) {
        return false;
      }
    },
    // 专利引用/被引用数据
    async getPatentReference (type, page = 1) {
      this.referenceLoading = false;
      try {
        let oldArr = this.patentDetailsData[type];
        if (!oldArr) {
          if (type == "cits") {
            this.referenceDataOne.data = [];
            this.referenceDataOne.pageTotal = 0;
          } else {
            this.referenceDataTwo.data = [];
            this.referenceDataTwo.pageTotal = 0;
          }
          this.referenceLoading = true;
          return false;
        }
        let ANOArr = oldArr.map((item) => {
          return item.ano;
        });
        let { data, status } = await getPatentReference(ANOArr, page);
        if (status == 200) {
          data.data.forEach((item) => {
            item["monitor_load"] = false;
          });
          if (type == "cits") {
            this.referenceDataOne.data = data.data;
            this.referenceDataOne.pageTotal = data.total *= 1;
          } else {
            this.referenceDataTwo.data = data.data;
            this.referenceDataTwo.pageTotal = data.total *= 1;
          }
          this.referenceLoading = true;
        }
      } catch (error) {
        return false;
      }
      if (type == "cits" && page == 1) {
        await this.getPatentReference("citByRefds", 1);
      }
    },
    // 专利族
    async getPatentFamily (page = 1) {
      try {
        let oldArr = this.patentDetailsData.fimalyObjList;
        if (!oldArr) {
          return false;
        }
        let PNSArr = oldArr.map((item) => {
          return item.pns;
        });
        let { data, status } = await getPatentFamily(PNSArr, page);
        if (status == 200) {
          this.patentFamilyData.data = data.data;
        }
      } catch (error) {
        return false;
      }
    },
    // 专利监控
    async monitorBtn (pid) {
      if (!this.$store.getters.userToken)
        return this.$message.warning("请先登录");
      this.monitor_load = true;
      try {
        let { status } = await setPatentMonitor({keywords:pid,monitor_status:this.catalogPatent.associate_patent_status?2:1});
        if (status == 200) {
          this.catalogPatent.associate_patent_status =
            !this.catalogPatent.associate_patent_status;
          this.$message.success(
            this.catalogPatent.associate_patent_status
              ? "监控成功"
              : "取消监控成功"
          );
          if (
            this.catalogPatent.associate_patent_status
            && this.catalogPatent.ac.toUpperCase() == 'CN'
            && (this.catalogPatent.lssc == '1' || this.catalogPatent.lssc == '有效' || this.catalogPatent.lssc == '有权')
          ) {
            this.catalogPatent.associate_annualfee_status = true;
          }
        } else {
          this.$message.error(
            this.catalogPatent.associate_patent_status
              ? "取消监控失败"
              : "监控失败"
          );
        }
        this.monitor_load = false;
      } catch (error) {
        this.monitor_load = false;
      }
    },
    // 年费监控
    async monitorAnnualCostBtn (ano) {
      if (!this.$store.getters.userToken)
        return this.$message.warning("请先登录");
      this.monitor_annualCost_load = true;
      try {
        let { status } = this.catalogPatent.associate_annualfee_status
          ? await delPatentMonitorAnnualCost({keywords:ano,monitor_status: 2})
          : await setPatentMonitorAnnualCost({keywords:ano,monitor_status: 1});
        if (status == 200) {
          this.catalogPatent.associate_annualfee_status =
            !this.catalogPatent.associate_annualfee_status;
          this.$message.success(
            this.catalogPatent.associate_annualfee_status
              ? "年费监控成功"
              : "取消年费监控成功"
          );
        } else {
          this.$message.error(
            this.catalogPatent.associate_annualfee_status
              ? "取消年费监控失败"
              : "仅可监控国内有效专利"
          );
        }
        this.monitor_annualCost_load = false;
      } catch (error) {
        this.monitor_annualCost_load = false;
      }
    },
    // 专利详情--列表监控
    async listMonitorBtn (pid, ind, type) {
      if (!this.$store.getters.userToken)
        return this.$message.warning("请先登录");
      this[type].data[ind].monitor_load = true;
      try {
        let { status } = await setPatentMonitor(pid);
        if (status == 200) {
          this[type].data[ind].associate_patent_status =
            !this[type].data[ind].associate_patent_status;
          this.$message.success(
            this[type].data[ind].associate_patent_status
              ? "监控成功"
              : "取消监控成功"
          );
        } else {
          this.$message.error(
            this[type].data[ind].associate_patent_status
              ? "取消监控失败"
              : "监控失败"
          );
        }
        this[type].data[ind].monitor_load = false;
      } catch (error) {
        this[type].data[ind].monitor_load = false;
      }
    },

    // 专利附图-查看更多
    viewMore () {
      this.figurePage += 1;
      this.imgArray = [
        ...this.imgArray,
        ...this.imgArrayAll.splice(
          (this.figurePage - 1) * 16,
          this.figurePage * 16
        ),
      ];
    },
    // 切换tabs时请求当前tab内所需数据
    handleTabs (tab) {
      switch (tab.name) {
        case "tabs5":
          if (!this.legalDataTwo.data) {
            this.getPatentLawHistory();
          }
          this.getPatentDecision(this.catalogPatent.ano);
          break;
        case "tabs6":
          this.getPatentTransfer();
          this.getPatentLicense();
          this.getPatentPledge();
          break;
        case "tabs7":
          this.getPatentReference("cits");
          break;
        case "tabs8":
          this.getPatentFamily();
          break;

        default:
          break;
      }
    },
    // 专利运营数据许可/质押--详情数据
    handleOperatingVisible (type, valData) {
      if (type == "license") {
        this.operatingVisibleTitle = "许可";
        this.operatingLicenseVisibleData.data = valData;
      } else {
        this.operatingVisibleTitle = "质押";
        this.operatingPledgeVisibleData.data = valData;
      }
      this.operatingVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.details-info {
  padding: 36px 0;
  .details-name {
    width: 58%;
  }
  .details-text {
    li {
      margin: 0 38px 10px 0;
    }
  }
}
.details-main {
  .details-main-item {
    // 专利附图
    &.patent-figure {
      margin-bottom: 13px;
      .patent-figure-img {
        width: 100%;
        height: 160px;
        border-radius: 4px;
      }
    }
    // 引用数据
    .patent-reference {
      max-height: 547px;
      overflow: hidden;
      background-color: #ecf2ff;
      border-radius: 4px;
      .patent-reference-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
// 专利附图-查看更多
.view-more {
  transform: translateX(-50%);
  margin-left: 50%;
  background-color: #e9ebf2;
  border-radius: 4px;
}
</style>