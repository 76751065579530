<template>
  <div style="height:calc(100vh - 100px);">
    <SeeksRelationGraph ref="seeksRelationGraph" :options="graphOptions" :on-node-click="onNodeClick" />
  </div>
</template>

<script>
import SeeksRelationGraph from 'relation-graph'
export default {
  name: 'MyRelationGraph',
  props: {
    rootId: Object,
    dataLeft: {
      typeof: Array,
      default: []
    },
    dataRight: {
      typeof: Array,
      default: []
    },
  },
  components: { SeeksRelationGraph },
  data () {
    return {
      nodes: [],
      linksLeft: [],
      linksRight: [],
      graphOptions: {
        allowShowMiniToolBar: false,//是否显示工具栏
        disableZoom: true,//是否禁用图谱的缩放功能
        disableDragNode: true,// 是否禁用图谱中节点的拖动
        defaultJunctionPoint: 'lr',//默认的连线与节点接触的方式（border:边缘/ltrb:上下左右/tb:上下/lr:左右）当布局为树状布局时应使用tb或者lr，这样才会好看
        defaultNodeShape: 1,//默认的节点形状，0:圆形；1:矩形
        defaultNodeWidth: 200, //默认的节点宽度
        defaultNodeHeight: 40, //默认的节点高度
        defaultNodeBorderWidth: 0,//默认的节点边框粗细（像素）
        defaultLineColor: 'rgba(55, 153, 254, 1)',//默认的线条颜色
        defaultLineShape: 6,//默认的线条样式（1:直线/2:样式2/3:样式3/4:折线/5:样式5/6:样式6）
        defaultLineWidth: 2,//默认的线条宽度
        defaultNodeColor: 'rgba(55, 153, 254, 1)',//默认的节点背景颜色
        moveToCenterWhenResize: false,// 当图谱的大小发生变化时，是否重新让图谱的内容看起来居中
        fixed: true,
        //布局方式
        layouts: [
          {
            layoutName: 'tree',
            from: 'left',
            max_per_width: '300',
            max_per_height: 50,
            centerOffset_x: (this.dataRight.length && !this.dataLeft.length) ? 150 : 300,
            centerOffset_y: -150,
          }
        ],
        //默认的线条箭头样式
        defaultLineMarker: {
          show: false
        },
      }
    }
  },
  mounted () {
    this.dataLeft.forEach(item => {
      this.nodes.push({ id: item.pid, text: item.ano })
      this.linksLeft.push({ from: item.pid, to: this.rootId.id })
    })
    this.dataRight.forEach(item => {
      this.nodes.push({ id: item.pid, text: item.ano })
      this.linksRight.push({ from: this.rootId.id, to: item.pid })
    })

    this.showSeeksGraph()
  },
  methods: {
    showSeeksGraph (query) {
      var __graph_json_data = {
        'rootId': this.rootId.id,
        'nodes': [
          this.rootId, ...this.nodes
        ],
        'links': [...this.linksLeft, ...this.linksRight]
      }
      this.$refs.seeksRelationGraph.setJsonData(__graph_json_data, (seeksRGGraph) => {
        // 这些写上当图谱初始化完成后需要执行的代码
      })
    },
    onNodeClick (nodeObject, $event) {
      this.$router.push({ path: '/patent-details', query: { pid: nodeObject.id } })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .rel-map-canvas {
  height: 100% !important;
  margin-left: 0 !important;
  .rel-node-shape-1 {
    padding: 0;
    cursor: pointer;
    &.rel-node-checked {
      box-shadow: none !important;
    }
    .c-node-text span {
      margin-right: 0;
    }
  }
  svg {
    height: 100% !important;
  }
}
</style>